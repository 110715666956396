@charset 'utf-8';

@import 'settings';
@import 'foundation';
//@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-card;
// @include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
// @include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
// @include foundation-switch;
@include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;

// @include motion-ui-transitions;
// @include motion-ui-animations;

@import '../../bower_components/font-awesome/scss/font-awesome';

// Owl slider https://github.com/OwlCarousel2/OwlCarousel2
// @import '../../bower_components/owl.carousel/src/scss/core';
// @import '../../bower_components/owl.carousel/src/scss/animate';
// @import '../../bower_components/owl.carousel/src/scss/autoheight';
// @import '../../bower_components/owl.carousel/src/scss/lazyload';
// @import '../../bower_components/owl.carousel/src/scss/video';
// @import '../../bower_components/owl.carousel/src/scss/theme.default';
// @import 'components/owl_carousel';

.shadow {
    box-shadow: 0 7px 15px $medium-gray;
}

body, html {
    height: 100%;
}

.main {
    min-height: 100%;
    margin-bottom: -($footer-height);
    &:after {
        content: '';
        display: block;
        height: $footer-height
    }
}

/************************/
/*        Header:       */
/************************/
header.header {
    padding: 1rem 0;
    //background-color: #fff;
}

/************************/
/*      Navigation:     */
/************************/
#main-menu ul.menu:not(.vertical) {
    li {
        &:not(:last-child) > a {
            border-right: 1px solid $white;
        }
        &:not(.active) > a {
            color: $white;
            transition: background 0.25s ease-in-out;
            &:hover {
                background: $green;
            }
        }
    }
    &.sub-pages {
        li > a {
            margin-right: 0.5rem;
        }
        li:not(.active) {
            a {
                background-color: $red;
            }
        }
    }
}

/************************/
/*      Content:        */
/************************/
.off-canvas-content {
    //background-color: #fcfcfc;
    // background-image: url('../img/absurdity.png');
}
.main-content {
    padding: 2rem 0;
    background-color: $white;
    h1 {
        color: $red;
    }
    h2, h3, h4, h5, h6 {
        color: $blue;
    }
    a {
        color: darken($green, 25%);
    }
}

.komitee {
    img {
        width: 100px;
    }
}

/************************/
/*      Sidebar:        */
/************************/
.sidebar {

    .widget {
        h3 {
            color: $red;
            @include breakpoint(large up) {
                font-size: 1.5rem;
            }
            @include breakpoint(medium up) {
                font-size: 1.35rem;
            }
        }
    }

}

/************************/
/*     Footer stuff     */
/************************/
footer.footer {
    min-height: $footer-height;
    background-color: $footer-background;
    border-top: 1px solid $red;
    h1, h2, h3, h4, h5, h6 {
        color: $red;
    }
    p {
        margin: 0;
    }
    .row {
        padding: 1rem 0;
    }
    ul.past-years {
        margin-bottom: 0.25rem;
        li {
            &:first-child a {
                margin-left: 0;
            }
            a {
                border: 1px solid $green;
                border-radius: 3px;
                padding: 5px;
                display: inline-block;
                margin: 0 0.8rem;
                color: $blue;
                font-weight: bold;
                background-color: $white;
                transition: transform 0.2s;
                &:hover {
                    transform: scale(1.05);
                }
            }
            &:after {
                // content: "\2022";
                content: "\f105";
                font: normal normal normal 20px/1 FontAwesome;
                display: inline-block;
                color: $red;
            }
            &:last-child:after {
                content: none;
            }
        }
    }
}

/************************************************************/
/*     Media queries                                        */
/* http://foundation.zurb.com/sites/docs/media-queries.html */
/************************************************************/

.example-element {
    @include breakpoint(small) {
        background-color: red;
    }
    @include breakpoint(medium) {
        background-color: green;
    }
    @include breakpoint(large) {
        background-color: gold;
    }
    @include breakpoint(xlarge) {
        background-color: yellow;
    }
    @include breakpoint(xxlarge) {
        background-color: white;
    }

    @media print {
        display: none;
    }
}

@media print {
    header img {
        width: 100%;
        height: auto;
    }
}